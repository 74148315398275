<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/task.png"
             alt />
        <span class="center">服务包管理</span>
        <img src="../../assets/right.png"
             alt />
        <span class="center">服务包列表</span>
        <img src="../../assets/right.png"
             alt />
        <span class="center">服务包详情</span>
        <span style="color: cornflowerblue; margin-left: 20px"
              @click="goBack">返回</span>
      </div>
      <div style="display: flex; align-items: center"></div>
    </div>
    <el-card class="box-card">
      <Divider :title="'基本信息'"></Divider>
      <el-descriptions :column="3">
        <el-descriptions-item label="服务包编号">
          {{
          code
          }}
        </el-descriptions-item>
        <el-descriptions-item label="服务包名称">
          {{
          detailInfo.name
          }}
        </el-descriptions-item>
        <el-descriptions-item label="数据总计">
          共{{ detailInfo.dispatchNum }}条数据，结算总额￥{{
          detailInfo.totalSalary
          }}
        </el-descriptions-item>
        <el-descriptions-item label="发包时间">
          {{
          detailInfo.creatTime
          }}
        </el-descriptions-item>
        <el-descriptions-item label="工资包名称">
          {{
          detailInfo.taskName
          }}
        </el-descriptions-item>
        <el-descriptions-item label="工资包时间">
          {{ detailInfo.startTime }}-{{
          detailInfo.endTime
          }}
        </el-descriptions-item>
        <el-descriptions-item label="服务包备注">
          {{
          detailInfo.remark
          }}
        </el-descriptions-item>
      </el-descriptions>
      <Divider :title="'发薪企业信息'"></Divider>
      <el-tabs v-model="activeName"
               :stretch="true"
               @tab-click="getTabs">
        <el-tab-pane v-for="item in dispatch"
                     :key="item[0].code"
                     :name="item[0].code">
          <span slot="label">{{ item[0].enterprise.name }}</span>
          <!-- <div style="margin: 10px 0;width: 100%;text-align: left;">工资包</div> -->

          <!-- <div style="margin: 10px 0;width: 100%;text-align: left;font-weight: 700;">工资包 ↓</div> -->
          <el-tabs v-model="activeName2"
                   type="border-card"
                   :stretch="true">
            <el-tab-pane v-for="items in item"
                         :key="items.code"
                         :name="items.code">
              <span slot="label">
                {{ items.name }}
                <span v-if="items.isTax"
                      style="color:green">税</span>
              </span>
              <el-descriptions :column="3">
                <el-descriptions-item label="归属项目">
                  {{
                  items.project.name
                  }}
                </el-descriptions-item>
                <el-descriptions-item label="结算金额">￥{{ items.total }}</el-descriptions-item>
                <el-descriptions-item label="工资包状态">
                  <el-tag size="small"
                          v-if="items.state === '0'">待分配</el-tag>
                  <el-tag size="small"
                          v-if="items.state === '1'">审批中</el-tag>
                  <el-tag size="small"
                          v-if="items.state === '2'">复核中</el-tag>
                  <el-tag size="small"
                          v-if="items.state === '3'">被驳回</el-tag>
                  <el-tag size="small"
                          v-if="items.state === '4'">已完成</el-tag>
                  <el-tag size="small"
                          v-if="items.state === '5'">已撤销</el-tag>
                </el-descriptions-item>
              </el-descriptions>
              <el-table :data="items.reg"
                        style="width: 100%">
                <el-table-column prop="userName"
                                 label="姓名"></el-table-column>
                <el-table-column prop="userIdCardNumber"
                                 label="身份证号"></el-table-column>
                <el-table-column label="时薪">
                  <template slot-scope="scope">
                    <span>{{ scope.row.other.hourSalary }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="出勤工时">
                  <template slot-scope="scope">
                    <span>{{ scope.row.other.workDays.length * 4 }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="出勤天数">
                  <template slot-scope="scope">
                    <span>{{ scope.row.other.workDays.length }}</span>
                  </template>
                </el-table-column>

                <el-table-column v-for="(item, index) in headerTitle"
                                 :key="index"
                                 :label="item">
                  <template slot-scope="scope">
                    {{
                    scope.row.other[item]
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="结算金额">
                  <template slot-scope="scope">
                    <span>{{ scope.row.salary }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>

          <!-- <el-tabs v-model="activeName2" :stretch="true">
            <el-tab-pane v-for="items in item" :key="items.code" :name="items.code">
              <span slot="label">
                {{ items.name }}
                <span v-if="items.isTax" style="color:green">税</span>
              </span>
              <el-descriptions :column="3">
                <el-descriptions-item label="归属项目">
                  {{
                  items.project.name
                  }}
                </el-descriptions-item>
                <el-descriptions-item label="结算金额">￥{{ items.total }}</el-descriptions-item>
                <el-descriptions-item label="工资包状态">
                  <el-tag size="small" v-if="items.state === '0'">待分配</el-tag>
                  <el-tag size="small" v-if="items.state === '1'">审批中</el-tag>
                  <el-tag size="small" v-if="items.state === '2'">复核中</el-tag>
                  <el-tag size="small" v-if="items.state === '3'">被驳回</el-tag>
                  <el-tag size="small" v-if="items.state === '4'">已完成</el-tag>
                  <el-tag size="small" v-if="items.state === '5'">已撤销</el-tag>
                </el-descriptions-item>
              </el-descriptions>
              <el-table :data="items.reg" style="width: 100%">
                <el-table-column prop="userName" label="姓名"></el-table-column>
                <el-table-column prop="userIdCardNumber" label="身份证号"></el-table-column>
                <el-table-column label="时薪">
                  <template slot-scope="scope">
                    <span>{{ scope.row.other.hourSalary }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="出勤工时">
                  <template slot-scope="scope">
                    <span>{{ scope.row.other.workDays.length * 4 }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="出勤天数">
                  <template slot-scope="scope">
                    <span>{{ scope.row.other.workDays.length }}</span>
                  </template>
                </el-table-column>

                <el-table-column v-for="(item, index) in headerTitle" :key="index" :label="item">
                  <template slot-scope="scope">
                    {{
                    scope.row.other[item]
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="结算金额">
                  <template slot-scope="scope">
                    <span>{{ scope.row.salary }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>-->
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>

import { api } from '../../api/base/index';
export const totalTaskInfo = api()('totalTask.info.json');
export default {
  components: {
    Divider: () => import('../../components/divider.vue'),
  },
  data () {
    return {
      code: '',
      detailInfo: {},
      activeName: '',
      headerTitle: [],
      enterpriseName: '',
      activeName2: '',
      dispatch: [],
      obj: {},
    };
  },
  mounted () {
    this.code = this.$route.query.code;
    this.loadData();
  },
  methods: {
    loadData () {
      const params = {
        code: this.code,
      };
      totalTaskInfo(params).then((res) => {
        this.detailInfo = res;
        var array = res.dispatch
        var obj = {}, newArr = [];
        array.forEach(function (item) {
          //根据对象的属性是唯一的，将值作为对象的属性名
          if (!obj[item.enterprise.code]) {
            var arr = [];
            arr.push(item);
            newArr.push(arr);
            obj[item.enterprise.code] = item.enterprise.code;
          } else {
            newArr.forEach(function (value) {
              //如果已经存在  就循环新组的值将值插入属性相同的数组里   为了防止重复添加   只要和第一个比较就可以了
              if (value[0].enterprise.code == item.enterprise.code) {
                value.push(item)
              }
            })
          }
        })

        this.dispatch = newArr
        this.activeName = this.dispatch[0][0].code;
        this.activeName2 = this.dispatch[0][0].code;
        console.log(this.dispatch)

        this.obj = res.dispatch[0].reg[0].other;
        var reg = /^[\u4E00-\u9FA5]+$/;
        for (var key in this.obj) {
          if (reg.test(key)) {
            this.headerTitle.push(key);
          }
        }

        console.log(this.headerTitle, this.detailInfo);
      });
    },
    getTabs (val) {
      this.activeName2 = this.activeName2 = this.dispatch[val.index][0].code;
      console.log(this.activeName2, 222)
    },
    goBack () {
      this.$router.go(-1);
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
  },
};
</script>
<style lange="scss" scoped>
/deep/.el-tabs--border-card > .el-tabs__header::before {
  content: "工资包";
  font-size: larger;
  line-height: 35px;
  font-weight: 700;
}
</style>
